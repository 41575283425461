<template>
  <div>
    <span v-on:click="() => showTop = !showTop" class="span-expand-shrink">
     <span style="color: darkblue; font-size: 18px;">

      <feather-icon :icon="showTop?'Minimize2Icon':'Maximize2Icon'" size="11"/>
     <!-- {{showTop?'&#x21d9;':'&#x21d7;'}}-->
      </span>   {{ showTop?'Collapse': 'Expand' }}
      </span>

    <sop-filters :is-filters-sidebar-active.sync="$store.state.designUI.btn_header.showFilter"
      @filters-data="setFiltersData" />


      <b-row class="match-height" >


        <b-col :cols="showTop?12:5" style="padding-right: 0%;" >
          <tabla-simulacion :filters-data="filtersData" ></tabla-simulacion>
        </b-col>
        <b-col :cols="showTop?12:7" style="padding-left: 6px;" >
          <sop-chart :filters-data="filtersData"></sop-chart>
        </b-col>
      </b-row>

      <b-row >
        <b-col cols="12">
          <tabla-sellin-proy :filters-data="filtersData"></tabla-sellin-proy>
        </b-col>
      </b-row>

      <!-- TablaForecastComp -->
      <b-row >
        <b-col cols="12">
          <tabla-forecast-comp :filters-data="filtersData"></tabla-forecast-comp>
        </b-col>
      </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol,
  BButton, BTab, BTabs,
} from 'bootstrap-vue'
import SopPivotBpu from './SopPivotBpu.vue'
import SopPivotBpuVariacion from './SopPivotBpuVariacion.vue'
import TablaSellinQuarterLyf from './TablaSellinQuarterLyf.vue'
import SopChart from './SopChart/SopChart.vue'
import SopFilters from './SopFilters.vue'
import SopFc from './SopFc/SopFc.vue'

import TablaSimulacion from './TablaSimulacion.vue'
import TablaSellinProy from './TablaSellinProy.vue'
import TablaForecastComp from './TablaForecastComp'
import store from '@/store'

export default {
  components: {

    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,

    SopPivotBpu,
    SopPivotBpuVariacion,
    SopChart,
    SopFilters,
    SopFc,
    TablaSellinQuarterLyf,
    TablaSimulacion,
    TablaSellinProy,
    TablaForecastComp
    //CustomBarChart
  },
  data() {
    return {
      isFiltersSidebarActive: false,
      filtersData: {
        bpu: [],
        brand_category: [],
        application_form: [],
        canal: [],
        clasificacion: [],
        year: [],
        month: []
      },

      showTop: false
    }
  },
  created() {
    this.initialBtnHeader()

  },
  destroyed() {
    this.initialBtnHeader(false)

    store.commit('designUI/BTN_HEADER_data', {})
  },
  methods: {
    setFiltersData(data) {
      this.filtersData = Object.assign({}, data)

      store.commit('designUI/BTN_HEADER_data', Object.assign({}, data))
    },
    initialBtnHeader(show = true) {
      store.commit('designUI/SIMULATION_SHOW_BTN', show)
    },
/*
    setcheckBoxData(data2){
      const opcion1 = '__BVID__237_BV_option_1'
      const opcion2 = '__BVID__237_BV_option_2'
      const opcion3 = '__BVID__237_BV_option_3'
      const opcion4 = '__BVID__237_BV_option_4'

    }*/
  },

}
</script>

<style>
.custom-sticky {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.tab-content>.tab-pane:not(.active),
.pill-content>.pill-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;

}

.vertical-layout.vertical-menu-modern.menu-collapsed .app-content{
  margin-left: 70px;
}

.vertical-layout.vertical-menu-modern.menu-expanded .app-content{
  margin-left: 250px;
}

.card{
  box-shadow: 0 0px 0px 0 rgba(34, 41, 47, 0.1);
}
/* STYLE BOTÓN EXPADIR */
.span-expand-shrink{
  cursor: pointer; 
  position: absolute;
  z-index: 1000;
  float: right;
  right: 10px;
  top: 70px;
  background-color: beige;
  padding: 0px 15px;
}
</style>
